import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { Link, useLocation } from 'react-router-dom';
import {getProduct, updateProductStatus } from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Toggle from 'react-toggle';
import { Button } from 'reactstrap';
import axios from "axios";
import { GET_PRODUCTS } from "../../../redux/type";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";


var config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const Fixed = () => {
    const dispatch = useDispatch()
    const fixedList = useSelector(state=>state.Zeed.productList)
    const location = useLocation();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [activeParam, setActiveParam] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const active = queryParams.get('active') || '';
        dispatch(setLoading(true));
        setActiveParam(active);
        fetchPageData(1, active);
    }, [location.search,searchQuery]);
    
    const fetchPageData = (pageNum,activeParam) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
            isActive:activeParam,
            search: searchQuery,
        };
        axios
            .post(Constant.getAPI() + "/admin/backend-getallpost", DATA, config)
            .then((res) => {
                // window.setLoading(true)
                dispatch({
                    type: GET_PRODUCTS,
                    payload: res.data.data,
                    totalRows: res.data.totalRows
                });
                setTotalRows(res.data.totalRows)
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err)
                dispatch(setLoading(false))
            }
            );
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum, activeParam);
    };
    
    const handlePerPageChange = (event) => {
        const perPageValue = Number(event.target.value);
        setPerPage(perPageValue);
        fetchPageData(1, activeParam);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        fetchPageData(1);
    };

    // const getRolList = ()=>{
    //     var DATA={
    //         startRange: '0',
    //         count: "10000",
    //         type: "Fixed",
    //         all: "1"
    //     }
    //     dispatch(getProduct(DATA))
    // }

    const handleToggleChange = (id, active) => {
        var data = {
            id: id,
            active: active,
        }
        // console.log(data);
        dispatch(updateProductStatus(data));
    }

    const filteredUsList = fixedList.filter(post => {
        if (!activeParam) return true;
        return activeParam === '1' ? post.active : !post.active;
    });

    const columns = [
            {
                label: "Image",
                field: "Media", 
            },
            {
                label: "Title: EN",
                field: "title", 
            },
            {
                label: "Title: Ar",
                field: "title_ar", 
            },
            // {
            //     label: "Participants",
            //     field: "biddersCount", 
            // },
            {
                label: "Category Name: Eng",
                field: "Category", 
            },
            {
                label: "Category Name: Ar",
                field: "Category_ar", 
            },
            {
                label: "Shop Name",
                field: "shop", 
            },
            // {
            //     label: "Description in English",
            //     field: "about", 
            // },
            // {
            //     label: "Description in Arabic",
            //     field: "about_ar", 
            // },
            {
                label: "Price",
                field: "price", 
            },
            {
                label: "Quantity",
                field: "quantity", 
            },
            {
                label: "Variants",
                field: "Variants", 
            },
            {
                label: "Date",
                field: "createdAt", 
            },
            {
                label: "Status",
                field: "active", 
            },
            {
                label: "Action",
                field: "id", 
            },
    ];

    const rows = filteredUsList?.map((Item)=>{return {
        Media: <img src={Item?.Media?.[0]?.url} height={50} />,
        title: Item?.title,
        title_ar: Item?.title_ar,
        // biddersCount: <Link to={`/product/AuctionParticipants/${Item?.id}`} className="mdi mdi-eye"></Link>,
        Category: Item?.Category?.name,
        Category_ar: Item?.Category?.name_ar,
        shop: Item?.shop?.shopName,
        quantity: Item?.quantity,
        price: Item?.price,
        about: Item?.about,
        about_ar: Item?.about_ar,
        Variants: <Link to={`/variant-list/${Item?.id}`} className="mdi mdi-eye"></Link>,
        createdAt: moment(Item?.createdAt*1000).format("L"),
        // status:Item?.status,
        active:<Toggle defaultChecked={Item.active} value={Item.active} onChange={(e) => handleToggleChange(Item.id, e.target.checked)} />,
        id: (
            <div>
             <Link to={"/product-Fixed/edit/" + Item.id} title="Edit" className="mdi mdi-grease-pencil"></Link>
              <Link to={`/product-Fixed/view/${Item?.id}`} title="View" className="mdi mdi-eye"></Link>
            </div>
        ),
    }})

    const data = {
        columns,
        rows,
      };
    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

    // console.log("fixedList",fixedList);
  return (
    <AUX>
        <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <div className='d-flex justify-content-end'>
                                    <Link to='/product-Fixed/add'>
                                        <Button size="sm" color="secondary" onClick >Add Product</Button>
                                    </Link>
                                </div>
                                  <div className="d-flex justify-content-end m-t-5 m-b-5">
                                      <input
                                          type="text"
                                          placeholder="Search..."
                                          value={searchQuery}
                                          onChange={handleSearch}
                                          className="form-control"
                                          style={{
                                              width: '200px',
                                              height: 'calc(1.5em + .5rem + 2px)',
                                              padding: '.25rem .5rem',
                                              fontSize: '.875rem',
                                              lineHeight: '1.5',
                                              borderRadius: '.2rem'
                                          }}
                                      />
                                  </div>
                                <MDBDataTable
                                    bordered
                                    theadColor="#2632384"
                                    paging={false}
                                    hover
                                    entries={20}
                                    data={data}
                                    searching={false}
                                />
                                <div className="pagination-controls">
                                      <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                                      <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                      <span>Page {currentPage} of {totalPages}</span>
                                      <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                      <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default Fixed
