import React, { useEffect, useState, useRef } from "react";
import AUX from "../../../hoc/Aux_";
import moment from 'moment';
import { getDashboard } from "../../../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import { addDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import ReactApexChart from 'react-apexcharts';
import Axios from "axios";
import Constant from "../../../Constant";
import { Link } from "react-router-dom";
import { DateRangePicker } from 'react-date-range';

const VendorDashboard = () => {

  const [state, setState] = useState({
    allOrderCount: 0,
    atStore: 0,
    readyForPickup: 0,
    pending: 0,
    accepted: 0,
    enRouteWarehouse: 0,
    assignedDriverReadyForPickup: 0,
    unassignedDriverReadyForPickup: 0,
  });

  var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
  };

  const dispatch = useDispatch();
  const dashboardList = useSelector((state) => state.Zeed.dashboard);

  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const dateRangePickerRef = useRef(null);

  const handleToggleDateRangePicker = () => {
    setShowDateRangePicker(!showDateRangePicker);
  };

  const handleSelect = (ranges) => {
    const selectedRange = ranges.selection;
    revenue({ dateRange: selectedRange })
    barGraph({ dateRange: selectedRange });
    pieChartForTopSelling({ dateRange: selectedRange });
    pieChartForLeastSelling({ dateRange: selectedRange });
    customerInsights({ dateRange: selectedRange });
    inventoryStatus({ dateRange: selectedRange });
    dispatch(getDashboard({ startRange: '0', count: "1000", dateRange: selectedRange }));
    setShowDateRangePicker(false);
    setRange([selectedRange]);
  };

  const [insights, setInsights] = useState({
    newCustomersCount: 0,
    repeatCustomersCount: 0,
    retentionRate: 0,
    avgTimeSpent: 0,
  });

  const [inventory, setInventory] = useState({
    outOfStockCount: 0,
    runningStockCount: 0,
  });

  const [revenues, setRevenue] = useState({
    totalProductSalesRevenue: 0,
    totalDeliveryCharges: 0,
  });

  // Date range state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  // bar graph
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: 'sales-by-categories-chart'
      },
      xaxis: {
        categories: []
      }
    },
    series: [{
      name: 'Sales',
      data: []
    }]
  });

  // pie chart top selling
  const [pieChartTopSellingData, setPieChartTopSellingData] = useState({
    series: [],
    options: {
      chart: {
        type: 'pie',
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  // pie chart least selling
  const [pieChartLeastSellingData, setPieChartLeastSellingData] = useState({
    series: [],
    options: {
      chart: {
        type: 'pie',
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  // pie chart revenue
  const [pieChartRevenueData, setPieChartRevenueData] = useState({
    series: [],
    options: {
      chart: {
        type: 'pie',
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  // Static date ranges
  const staticRanges = [
    {
      label: 'Today',
      range: () => ({
        startDate: new Date(),
        endDate: new Date()
      }),
      isSelected: (range) => isDateInRange(new Date(), range),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1)
      }),
      isSelected: (range) => isDateInRange(addDays(new Date(), -1), range),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate()
      }),
      isSelected: (range) => isDateInRange(moment().startOf('week').toDate(), range),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: moment().subtract(1, 'weeks').startOf('week').toDate(),
        endDate: moment().subtract(1, 'weeks').endOf('week').toDate()
      }),
      isSelected: (range) => isDateInRange(moment().subtract(1, 'weeks').startOf('week').toDate(), range),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
      }),
      isSelected: (range) => isDateInRange(moment().startOf('month').toDate(), range),
    }
  ];

  const isDateInRange = (date, range) => {
    const { startDate, endDate } = range;
    return date >= startDate && date <= endDate;
  };

  const barGraph = async (dateRange) => {
    try {
      const response = await Axios.post(Constant.getAPI() + "/dashboard/sales-By-Categories", dateRange, config);
      const data = response.data;

      const categories = data.map(item => item.categoryName);
      const sales = data.map(item => item.totalSales);

      setChartData({
        options: {
          ...chartData.options,
          xaxis: {
            categories
          }
        },
        series: [{
          name: 'Sales',
          data: sales
        }]
      });
    } catch (error) {
      console.error('Error fetching sales data', error);
    }
  };

  useEffect(() => {
    barGraph(range[0]);
  }, []);

  const pieChartForTopSelling = async (dateRange) => {
    try {
      const response = await Axios.post(Constant.getAPI() + "/dashboard/top-selling-products", dateRange, config);
      const data = response.data;

      const labels = data.map(item => item.productName);
      const series = data.map(item => item.totalAmount);

      setPieChartTopSellingData({
        ...pieChartTopSellingData,
        series,
        options: {
          ...pieChartTopSellingData.options,
          labels,
        },
      });
    } catch (error) {
      console.error('Error fetching top selling products:', error);
    }
  };

  useEffect(() => {
    pieChartForTopSelling();
  }, []);

  const pieChartForLeastSelling = async (dateRange) => {
    try {
      const response = await Axios.post(Constant.getAPI() + "/dashboard/least-selling-products", dateRange, config);
      const data = response.data;

      const labels = data.map(item => item.productName);
      const series = data.map(item => item.totalAmount);

      setPieChartLeastSellingData({
        ...pieChartLeastSellingData,
        series,
        options: {
          ...pieChartLeastSellingData.options,
          labels,
        },
      });
    } catch (error) {
      console.error('Error fetching top selling products:', error);
    }
  };

  useEffect(() => {
    pieChartForLeastSelling();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
        setShowDateRangePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    getRolList();
  }, []);

  const customerInsights = async (dateRange) => {
    const response = await Axios.post(Constant.getAPI() + "/dashboard/customer-insights", dateRange, config);
    setInsights(response.data);
  };

  useEffect(() => {
    customerInsights();
  }, []);

  const inventoryStatus = async (dateRange) => {
    const response = await Axios.post(Constant.getAPI() + "/dashboard/inventory-status", dateRange, config);
    setInventory(response.data);
  };

  useEffect(() => {
    inventoryStatus();
  }, []);

  const revenue = async (dateRange) => {
    const response = await Axios.post(Constant.getAPI() + "/dashboard/revenue", dateRange, config);
    setRevenue(response.data);
    // console.log(response.data, 'response.data');
    setPieChartRevenueData({
      series: [parseFloat(response.data.totalProductSalesRevenue), parseFloat(response.data.totalDeliveryCharges)],
      options: {
        ...pieChartRevenueData.options,
        labels: ['Product Sales', 'Delivery Charges']
      }
    });
  };

  useEffect(() => {
    revenue();
  }, []);

  const getRolList = () => {
    // var DATA = {
    //   startRange: "0",
    //   count: "1000",
    // };
    Axios
      .post(Constant.getAPI() + "/dashboard/sideBarVendorOrderBadges", {}, config)
      .then((res) => {
        setState({
          allOrderCount: res.data.all,
          atStore: res.data.atStore,
          readyForPickup: res.data.readyForPickup,
          pending: res.data.pending,
          accepted: res.data.accepted,
          enRouteWarehouse: res.data.enRouteWarehouse,
          assignedDriverReadyForPickup: res.data.assignedDriverReadyForPickup,
          unassignedDriverReadyForPickup: res.data.unassignedDriverReadyForPickup,
        });
      })
      .catch((err) => {
        console.log(err)
      }
      )
    dispatch(getDashboard());
  };

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">

          <div className="w-100" style={{ textAlign: 'end', position: 'relative' }}>
            <button
              onClick={handleToggleDateRangePicker}
              style={{
                marginBottom: '20px',
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              {showDateRangePicker ? 'Hide Filter' : 'Show Filter'}
            </button>
          </div>

          {showDateRangePicker && (
            <div ref={dateRangePickerRef} style={{ position: 'absolute', right: '0', top: '50px', zIndex: '999' }}>
              <DateRangePicker
                ranges={range}
                onChange={handleSelect}
                locale={enUS}
                staticRanges={staticRanges}
                inputRanges={[]}
              />
            </div>
          )}

          <div className="row">
            {/* <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-purple mr-0 float-right">
                  <i className="mdi mdi-basket"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter text-purple">
                    KWD {dashboardList.vendorTotalSale}
                  </span>
                  Sales
                </div>
                <div className="clearfix"></div>
                <p className=" mb-0 m-t-20 text-muted">
                  {" "}
                  Total Sales: KWD {dashboardList.vendorTotalSale}{" "}
                </p>
              </div>
            </div> */}
            <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-pink mr-0 float-right">
                  <i className="mdi mdi-cart"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a href="/all-order" className=" text-pink" style={{ textDecoration: "underline" }}>
                      {dashboardList.vendorOrderCount}
                    </a>
                  </span>
                  Orders
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Total Orders: {dashboardList.vendorOrderCount}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-pink mr-0 float-right">
                  <i className="mdi mdi-cart"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a href="/seller-pending-orders" className=" text-pink" style={{ textDecoration: "underline" }}>
                      {dashboardList.vendorPendingOrder}
                    </a>
                  </span>
                  Pending Orders
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Pending Orders: {dashboardList.vendorPendingOrder}
                </p>
              </div>
            </div>
            {/* <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-blue-grey mr-0 float-right">
                  <i className="mdi mdi-truck"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a href="/users" className="text-blue-grey">
                      {dashboardList.orderOnTheWayToBuyersCount}
                    </a>
                  </span>
                  Orders On route to buyers
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Orders On route to buyers:{" "}
                  {dashboardList.orderOnTheWayToBuyersCount}
                </p>
              </div>
            </div> */}
            <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-brown mr-0 float-right">
                  <i className="mdi mdi-black-mesa"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a href="/enroute-warehouse" className=" text-pink" style={{ textDecoration: "underline" }}>
                      {dashboardList.orderOnRouteToWarehouseCount}
                    </a>
                  </span>
                  Orders On route to warehouse
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Orders On route to warehouse:{" "}
                  {dashboardList.orderOnRouteToWarehouseCount}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-pink mr-0 float-right">
                  <i className="mdi mdi-truck-delivery"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a href="/at-store" className=" text-pink" style={{ textDecoration: "underline" }}>
                      {dashboardList.orderPendingPickupFromStoreCount}
                    </a>
                  </span>
                  Orders pending pickup from store
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Orders pending pickup from store:{" "}
                  {dashboardList.orderPendingPickupFromStoreCount}
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="clearfix bg-white text-center">
                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                  <h5 className="text-blue">Vendor Order Status</h5>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                      <p>Pending</p>
                      <h3>
                        <Link to={{ pathname: "/seller-pending-orders", }} className="text-dark" style={{ textDecoration: "underline" }}>
                          {state.pending}
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                      <p>Accepted</p>
                      <h3>
                        <Link to={{ pathname: "/seller-accepted-orders", }} className="text-dark" style={{ textDecoration: "underline" }}>
                          {state.accepted}
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                      <p>Ready for Pickup (Unassigned)</p>
                      <h3>
                        <Link to={{ pathname: "/ready-for-pickup-unassign", }} className="text-dark" style={{ textDecoration: "underline" }}>
                          {state.unassignedDriverReadyForPickup}
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                      <p>Ready for Pickup (Assigned)</p>
                      <h3>
                        <Link to={{ pathname: "/ready-for-pickup-assign", }} className="text-dark" style={{ textDecoration: "underline" }}>
                          {state.assignedDriverReadyForPickup}
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                      <p>Enroute to Warehouse</p>
                      <h3>
                        <Link to={{ pathname: "/enroute-warehouse", }} className="text-dark" style={{ textDecoration: "underline" }}>
                          {state.enRouteWarehouse}
                        </Link>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                      <p>Reached at Warehouse</p>
                      <h3>
                        <Link to={{ pathname: "/at-store", }} className="text-dark" style={{ textDecoration: "underline" }}>
                          {state.atStore}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-pink mr-0 float-right">
                  <i className="mdi mdi-cart"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a className=" text-pink">
                      {dashboardList.vendorPendingOrder}
                    </a>
                  </span>
                  Pending Orders
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Pending Orders: {dashboardList.vendorPendingOrder}
                </p>
              </div>
            </div> */}
            {/* <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-pink mr-0 float-right">
                  <i className="mdi mdi-cart"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a href="/users" className="text-blue-grey">
                      {dashboardList.vendorPickUpOrder}
                    </a>
                  </span>
                  Picked Up Orders
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Picked Up Orders:{" "}
                  {dashboardList.vendorPickUpOrder}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-pink mr-0 float-right">
                  <i className="mdi mdi-cart"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a href="/sellers" className="counter text-brown">
                      {dashboardList.vendorCompleteOrder}
                    </a>
                  </span>
                  Completed Orders
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Completed Orders:{" "}
                  {dashboardList.vendorCompleteOrder}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="mini-stat clearfix bg-white">
                <span className="mini-stat-icon bg-pink mr-0 float-right">
                  <i className="mdi mdi-cart"></i>
                </span>
                <div className="mini-stat-info">
                  <span className="counter">
                    <a className=" text-pink">
                      {dashboardList.vendorCancelledOrder}
                    </a>
                  </span>
                  Canceled Orders
                </div>
                <div className="clearfix"></div>
                <p className="text-muted mb-0 m-t-20">
                  Canceled Orders:{" "}
                  {dashboardList.vendorCancelledOrder}
                </p>
              </div>
            </div> */}
          </div>
        </div>

        <div className='row mt-4'>
          <div className="col-md-4">
            <div className="card m-b-20">
              <div className="card-body">
                <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Sales By Category</h4>
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card m-b-20">
              <div className="card-body">
                <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Top selling products</h4>
                <ReactApexChart
                  options={pieChartTopSellingData.options}
                  series={pieChartTopSellingData.series}
                  type="pie"
                  height={400}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card m-b-20">
              <div className="card-body">
                <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Least selling products</h4>
                <ReactApexChart
                  options={pieChartLeastSellingData.options}
                  series={pieChartLeastSellingData.series}
                  type="pie"
                  height={400}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            <div className="clearfix bg-white text-center">
              <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                <h5 className="text-blue">Customer Insights</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                    <p>New Customers</p>
                    <h3>
                      <Link
                        className="text-dark">
                        {insights.newCustomersCount}
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                    <p>Repeat Customers</p>
                    <h3>
                      <Link
                        className="text-dark">
                        {insights.repeatCustomersCount || 0}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="clearfix bg-white text-center">
              <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                <h5 className="text-blue">Inventory Status</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                    <p>Out of stock</p>
                    <h3>
                      <Link
                        className="text-dark">
                        {inventory.outOfStockCount}
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                    <p>Running Stock</p>
                    <h3>
                      <Link
                        className="text-dark">
                        {inventory.runningStockCount}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row mt-4">
          <div className="col-md-6">
            <div className="clearfix bg-white text-center">
              <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                <h5 className="text-blue">Revenue</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                    <p>Product Sales Revenue</p>
                    <h3>
                      <Link
                        className="text-dark">
                        {revenues.totalProductSalesRevenue}
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                    <p>total Delivery Charges</p>
                    <h3>
                      <Link
                        className="text-dark">
                        {revenues.totalDeliveryCharges}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card m-b-20">
              <div className="card-body">
                <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Revenue chart</h4>
                <ReactApexChart
                  options={pieChartRevenueData.options}
                  series={pieChartRevenueData.series}
                  type="pie"
                  height={300}
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </AUX>
  );
};

export default VendorDashboard;
