import React, { useState }  from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {loginUser} from '../../../redux/MainAction'

const Login = () => {
    const dispatch = useDispatch()
    const [ login , setLogin] = useState({
        email:"",
        password:""
    })
    const onHandleChange = (e) =>{
        const {value , name} = e.target
        setLogin({...login,[name]:value})
    }
    const onSubmit =(e)=>{
        e.preventDefault()
        var data = {
            email:login.email,
            password:login.password,
            role: 'admin'
            // loggedInWith:'normal'
        }
        dispatch(loginUser(data))
        // console.log("loged in" , data);
    }
  return (
    <AUX>
    <div className="accountbg"></div>
    <div className="wrapper-page">

     <div className="card">
         <div className="card-body">

             <h3 className="text-center m-0">
                 <Link to="/"  className="logo logo-admin"><img src="assets/images/favicon-32x32.png" height="30" alt="logo" /></Link>
             </h3>

             <div className="p-3">
                 <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
                 <p className="text-muted text-center">Sign in to continue to Zeed Admin.</p>

                 <form className="form-horizontal m-t-30" action="/">

                     <div className="form-group">
                         <label for="username">Email</label>
                         <input type="email" name='email' value={login.email} onChange={onHandleChange} className="form-control" id="username" placeholder="Enter email" />
                     </div>

                     <div className="form-group">
                         <label for="userpassword">Password</label>
                         <input type="password" name='password'  value={login.password} onChange={onHandleChange} className="form-control" id="userpassword" placeholder="Enter password" />
                     </div>

                     <div className="form-group row m-t-20">
                         <div className="col-sm-6">
                             <div className="custom-control custom-checkbox">
                                 <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                 <label className="custom-control-label" for="customControlInline">Remember me</label>
                             </div>
                         </div>
                         <div className="col-sm-6 text-right">
                             <button onClick={onSubmit} className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                         </div>
                     </div>

                     <div className="form-group m-t-10 mb-0 row">
                         <div className="col-12 m-t-20">
                             <Link to="pages_recoverpw" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                         </div>
                     </div>
                 </form>
             </div>
         </div>
     </div>

     <div className="m-t-40 text-center">
         <p className="text-white">Don't have an account ? <Link to="pages_register" className="font-500 font-14 text-white font-secondary"> Signup Now </Link> </p>
         <p className="text-white">© {new Date().getFullYear()-1} -  {new Date().getFullYear()} Zeed</p>
     </div>
    </div>
    </AUX>
  )
}

export default Login