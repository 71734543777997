import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { MDBDataTable } from "mdbreact";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  GET_ADMIN_KPI,
  GET_ALL_SELLER,
  GET_ALL_USER,
  GET_ATTRIBUTE,
  GET_BANK_LIST,
  GET_CATEGORY,
  GET_COUNTRY,
  GET_COUPON,
  GET_NOTIFICATIONS,
  GET_ORDER,
  GET_ORDER_STATUS,
  GET_PACKAGE,
  GET_PRODUCTS,
  GET_REPORTED_POSTS,
  GET_ROLES,
  GET_ROLES_MODULE_LIST,
  GET_STATE,
  GET_CITY,
  GET_TIMESLOT,
  GET_WALLET_LIST,
  GET_WINNERS_LIST,
  SET_LOADING,
  USER_LOGIN_DETAILS,
  GET_SELLER_DROPDOWN,
  GET_DASHBOARD,
  GET_USERS_DROPDOWN,
  GET_SUB_CATEGORY_DROPDOWN,
  GET_DRIVERS_DROPDOWN,
} from "../../../redux/type";
import axios from "axios";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";
var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const OrderList = () => {
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const ordrList = useSelector((state) => state.Zeed.orders);
  const location = useLocation();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [orderStatusParam, setActiveParam] = useState("");
  const [filterOrderId, setFilterOrderId] = useState("");
  const [filterPaymentStatus, setFilterPaymentStatus] = useState("");
  const [filterOrderStatus, setFilterOrderStatus] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderStatus = queryParams.get("orderStatusId") || "";
    dispatch(setLoading(true));
    setActiveParam(orderStatus);
    fetchPageData(1, orderStatus);
  }, [location.search]);

  const fetchPageData = (pageNum, orderStatusParam) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
      orderStatusId: orderStatusParam,
      type: "O",
    };
    let setUrl = "";
    let adminUrl = "/order/backend-getAll";
    let vendorUrl = "/order/backend-vendor-getAll";
    if (role == "vendor") {
      setUrl = vendorUrl;
    } else {
      setUrl = adminUrl;
    }
    axios
      .post(Constant.getAPI() + setUrl, DATA, config)
      .then((res) => {
        dispatch({
          type: GET_ORDER,
          payload: res.data.data,
          totalRows: res.data.totalRows,
        });
        setTotalRows(res.data.totalRows);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    dispatch(setLoading(true));
    fetchPageData(pageNum, orderStatusParam);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    fetchPageData(1);
  };

  const handleOrderIdChange = (event) => {
    setFilterOrderId(event.target.value);
  };

  const handlePaymentStatusChange = (event) => {
    setFilterPaymentStatus(event.target.value);
  };

  const handleOrderStatusChange = (event) => {
    setFilterOrderStatus(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setFilterPhone(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setFilterStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setFilterEndDate(event.target.value);
  };

  const toggleFilterVisibility = () => {
    setShowFilter(!showFilter);
  };

  const filteredUsList = ordrList.filter((user) => {
    if (filterOrderId && !user.invoiceNumber.includes(filterOrderId)) {
      return false;
    }

    if (filterPaymentStatus && user.paymentStatus !== filterPaymentStatus) {
      return false;
    }

    if (filterOrderStatus && user.orderStatus !== filterOrderStatus) {
      return false;
    }

    if (filterPhone && !user.customer.mobileNumber.includes(filterPhone)) {
      return false;
    }

    if (!orderStatusParam) {
      return true;
    }
    if (
      orderStatusParam == "383a1c6f-d1ca-4aa8-ae70-4ba71f015e2b" &&
      user.orderStatus === "Placed"
    ) {
      return true;
    }
    if (
      orderStatusParam == "c5ce32ce-d8e5-4c4c-b679-e892440595e9" &&
      user.orderStatus === "Pending"
    ) {
      return true;
    }
    if (
      orderStatusParam == "18add9a7-c9b2-47d2-8239-85020ca1db88" &&
      user.orderStatus === "Delivered"
    ) {
      return true;
    }
    if (
      orderStatusParam == "4cc48c4c-baed-426d-9a6d-a913b7f7719f" &&
      user.orderStatus === "Being Wrapped Up"
    ) {
      return true;
    }
    if (
      orderStatusParam == "cee1b25e-2b10-11ef-bd7f-0ad94c5b94d5" &&
      user.orderStatus === "Cancelled"
    ) {
      return true;
    }
    if (
      orderStatusParam == "e9b6b5c2-9dda-4c34-8fce-0666a173ddb8" &&
      user.orderStatus === "On the Way"
    ) {
      return true;
    }
    // if (
    //   orderStatusParam == "54f95aa1-e90d-4eac-a0e0-eeb8640f535b" &&
    //   user.orderStatus === "Ready for pickup"
    // ) {
    //   return true;
    // }
    if (
      orderStatusParam == "54b7c7d8-5715-4536-a330-d2641b0b8bf7" &&
      user.orderStatus === "Not delivered"
    ) {
      return true;
    }
    // if (
    //   orderStatusParam == "b6b76fe2-1081-4ae4-ae0d-55f11aa716af" &&
    //   user.orderStatus === "Reached at warehouse"
    // ) {
    //   return true;
    // }
    return false;
  });

  const columns = [
    {
      label: "Order ID",
      field: "invoiceNumber",
    },
    // {
    //   label: "Area",
    //   field: "Area",
    // },
    {
      label: "Date-Time",
      field: "createdAt",
    },
    {
      label: "Customer Name",
      field: "userName",
    },
    // {
    //   label: "Customer Email",
    //   field: "email",
    // },
    {
      label: "Customer Phone",
      field: "phone",
    },
    {
      label: "Customer Address",
      field: "address",
    },
    // {
    //   label: "Time Slot",
    //   field: "deliveryTimeSlot",
    // },
    {
      label: "Vendor Name",
      field: "vendorName",
    },
    {
      label: "Order Total",
      field: "orderTotal",
    },
    {
      label: "Payment Status",
      field: "paymentStatus",
    },
    {
      label: "Order Status",
      field: "orderStatus",
    },
    {
      label: "Action",
      field: "id",
    },
  ];

  const rows = filteredUsList.map((Item) => ({
    invoiceNumber: Item.invoiceNumber,
    // User: Item.customer.userName,
    Area: Item?.addressDetails?.City?.name || "",
    createdAt: moment.unix(Item.createdAt).format("DD-MMM-YYYY | hh:mm A"),
    deliveryTimeSlot: Item.deliveryTimeSlot,
    orderTotal: Item.orderTotal,
    vendorName: Item?.shopNames.join(", "),
    userName:
      role == "vendor" ? Item?.user?.userName : Item?.customer?.userName,
    email: role == "vendor" ? Item?.user?.email : Item?.customer?.email,
    address: `${Item.addressDetails?.label || ""} ${Item.addressDetails
      ?.block || ""} ${Item.addressDetails?.floor || ""} ${Item.addressDetails
      ?.flat || ""} ${Item.addressDetails?.street || ""} ${Item.addressDetails
      ?.City?.name || ""} ${Item.addressDetails?.City?.State?.name || ""} ${Item
      .addressDetails?.City?.State?.Country?.name || ""}`,
    phone:
      role == "vendor"
        ? Item?.user?.mobileNumber
        : Item?.customer?.mobileNumber,
    orderStatus:
      Item.orderStatus === "Placed" ? (
        <b
          style={{
            color: "green",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ) : Item.orderStatus === "Reached at warehouse" ? (
        <b
          style={{
            color: "blue",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          At store
        </b>
      ) : (
        <b
          style={{
            color: "red",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ),
    // orderStatus: Item.orderStatus === "Placed" ? <b style={{ color: 'green', marginTop: "0", marginBottom: 0, fontWeight: "600" }}>{Item.orderStatus}</b> : <b style={{ color: 'red', marginTop: "0", marginBottom: 0, fontWeight: "600"}}>{Item.orderStatus}</b>,
    paymentStatus:
      Item.paymentStatus === "Pending" ? (
        <p
          style={{
            color: "red",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          Pending
        </p>
      ) : Item.paymentStatus === "received" ? (
        <p style={{ color: "green", marginTop: "0", marginBottom: 0 }}>
          Success
        </p>
      ) : (
        <p style={{ color: "red", marginTop: "0", marginBottom: 0 }}>Failed</p>
      ),
    paymentStatus: (() => {
      switch (Item.paymentStatus) {
        case "Pending":
          return (
            <p
              style={{
                color: "orange",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Pending
            </p>
          );
        case "Received":
          return (
            <p
              style={{
                color: "green",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Success
            </p>
          );
        case "notProcessed":
          return (
            <p
              style={{
                color: "red",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Failed
            </p>
          );
        default:
          return (
            <p
              style={{
                color: "red",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Failed
            </p>
          );
      }
    })(),
    id: (
      <Link
        to={`/orders/${Item.id}`}
        title="View"
        className="mdi mdi-eye"
      ></Link>
    ),
  }));

  const data = {
    columns,
    rows,
  };
  const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div>
                    <button
                      onClick={toggleFilterVisibility}
                      style={{
                        marginBottom: "10px",
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {showFilter ? "Hide Filters" : "Show Filters"}
                    </button>
                    {showFilter && (
                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                          padding: "20px",
                          backgroundColor: "#fff",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          marginBottom: "20px",
                        }}
                      >
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label
                              htmlFor="orderId"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Order ID
                            </label>
                            <input
                              type="text"
                              id="orderId"
                              value={filterOrderId}
                              onChange={handleOrderIdChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="phone"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Customer Phone
                            </label>
                            <input
                              type="text"
                              id="phone"
                              value={filterPhone}
                              onChange={handlePhoneChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label
                              htmlFor="paymentStatus"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Payment Status
                            </label>
                            <select
                              id="paymentStatus"
                              value={filterPaymentStatus}
                              onChange={handlePaymentStatusChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Pending">Pending</option>
                              <option value="Received">Received</option>
                              <option value="notProcessed">Failed</option>
                            </select>
                          </div>
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="orderStatus"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Order Status
                            </label>
                            <select
                              id="orderStatus"
                              value={filterOrderStatus}
                              onChange={handleOrderStatusChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Placed">Placed</option>
                              <option value="Pending">Pending</option>
                              <option value="Delivered">Delivered</option>
                              <option value="Being Wrapped Up">
                                Being Wrapped Up
                              </option>
                              <option value="Cancelled">Cancelled</option>
                              <option value="On the Way">On the Way</option>
                              <option value="Not delivered">
                                Not delivered
                              </option>
                            </select>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label
                              htmlFor="startDate"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Start Date
                            </label>
                            <input
                              type="date"
                              id="startDate"
                              value={filterStartDate}
                              onChange={handleStartDateChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="endDate"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              End Date
                            </label>
                            <input
                              type="date"
                              id="endDate"
                              value={filterEndDate}
                              onChange={handleEndDateChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <MDBDataTable
                    bordered
                    theadColor="#2632384"
                    searching={false}
                    paging={false}
                    hover
                    entries={20}
                    data={data}
                  />
                  <div className="pagination-controls">
                    <button
                      className="pagination-btn first-btn"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      className="pagination-btn prev-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn next-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      className="pagination-btn last-btn"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .pagination-controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
        }
        select{
          width: 27%;
        }
        .pagination-btn {
          background-color: #263238;
          color: white;
          border: none;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 14px;
        }

        .pagination-btn:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }

        .pagination-btn:not(:disabled):hover {
          background-color: #263233;
        }
      `}</style>
    </AUX>
  );
};

export default OrderList;
