import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    addDriver,
    getDriverList,
    updateDriver,
} from "../../../redux/MainAction";

const DriverAdd = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const driveList = useSelector((state) => state.Zeed.driveList);

    const [driver, setCate] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobileNumber: "",

    });
    const [langId, setLangId] = useState("en");

    const handleLang = (e) => {
        setLangId(e.target.id);
    };

    const getRolList = () => {
        var DATA = {
            startRange: "0",
            count: "1000",
        };
        dispatch(getDriverList(DATA));
    };
    useEffect(() => {
        if (params.id) {
            const spr = driveList.filter((itm) => itm.id === params.id);
            setCate({
                firstName: spr[0]?.firstName,
                lastName: spr[0]?.lastName,
                email: spr[0]?.email,
                password: spr[0]?.password,
                mobileNumber: spr[0]?.mobileNumber,
            });
        }
    }, [params.id, langId]);

    useEffect(() => {
        getRolList();
    }, []);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setCate({ ...driver, [name]: value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (params.id) {
            const data = {
                id: params.id,
                firstName: driver?.firstName,
                lastName: driver?.lastName,
                email: driver?.email,
                password: driver?.password,
                mobileNumber: driver?.mobileNumber,
            };
            dispatch(updateDriver(data));
        } else {
            const data = {
                firstName: driver?.firstName,
                lastName: driver?.lastName,
                email: driver?.email,
                password: driver?.password,
                mobileNumber: driver?.mobileNumber,
            };
            dispatch(addDriver(data));
        }
    };

    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <ul
                                        className="nav nav-tabs"
                                        role="tablist"
                                        onClick={handleLang}
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                data-toggle="tab"
                                                href="#en"
                                                role="tab"
                                            >
                                                <span id="en" className="d-none d-md-block">
                                                    Drivers
                                                </span>
                                                <span className="d-block d-md-none">
                                                    <i className="mdi mdi-home-variant h5"></i>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content mt-3">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label>First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    name="firstName"
                                                    value={driver.firstName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    name="lastName"
                                                    value={driver.lastName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={driver.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    name="password"
                                                    value={driver.password}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Mobile Number</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Mobile Number"
                                                    name="mobileNumber"
                                                    value={driver.mobileNumber}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-end ">
                                            <button
                                                onClick={onSubmit}
                                                type="submit"
                                                className="btn btn-primary waves-effect waves-light mr-2"
                                            >
                                                Submit
                                            </button>
                                            <Link
                                                to="/category"
                                                className="btn btn-secondary waves-effect m-l-5"
                                            >
                                                Cancel
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AUX>
    );
};

export default DriverAdd;