import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { getSeller, setLoading } from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import Toggle from 'react-toggle'
import Axios from 'axios';
import Constant from '../../../Constant';
import Swal from 'sweetalert2';
import { GET_ALL_SELLER } from '../../../redux/type';

const Seller = () => {
  var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
  };

  const dispatch = useDispatch()
  const SellerList = useSelector(state => state.Zeed.sellerList)
  const [sellerList, setSellerList] = useState([])
  const location = useLocation();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "User Name",
        field: "userName",
      },
      {
        label: "Mobile No",
        field: "mobileNumber",
      },
      {
        label: "Business Name",
        field: "storeDetails",
      },

      {
        label: "Email",
        field: "email",
      },

      {
        label: "Seller Email",
        field: "sellerEmail",
      },
      {
        label: "Device Type",
        field: "deviceType",
      },
      {
        label: "Registered Date",
        field: "created",
      },
      {
        label: "Business Type",
        field: "type",
      },
      {
        label: "Registered",
        field: "isRegistered",
      },
      {
        label: "Display Status",
        field: "isVerify",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Action",
        field: "id",
      },
    ],
    rows: [],
  });

  const [type, setType] = useState('all')

  const handleChange = (e) => {
    setType(e?.target?.value)
  }

  useEffect(() => {
    dispatch(setLoading(true));
    fetchPageData(1);
    // getSellerList()
  }, []);

  // const getSellerList = () => {
  //   var DATA = {
  //     startRange: '0',
  //     count: "1000"
  //   }
  //   dispatch(getSeller(DATA))
  // }

  const fetchPageData = (pageNum, searchTerm = "") => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
      keyword: searchTerm
    };
    Axios
      .post(Constant.getAPI() + "/seller/backend-getAll", DATA, config)
      .then((res) => {
        dispatch({
          type: GET_ALL_SELLER,
          payload: res.data.data,
          totalRows: res.data.totalRows,
        });
        setTotalRows(res.data.totalRows);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    dispatch(setLoading(true));
    fetchPageData(pageNum);
  };

  const handleVariFyChange = (ID) => {
    var data = {
      userId: ID
    }
    Axios.post(Constant.getAPI() + '/admin/verifyStore', data, config).then(res => {
      if (res.data.status) {
        var data = {
          startRange: '0',
          count: "1000"
        }
        dispatch(getSeller(data));
      }
    })
  }
  useEffect(() => {
    // Assuming sellerList is a prop or a state variable
    const updatedRows = sellerList?.map((Itm) => {
      return {
        userName: Itm?.userName,
        firstName: Itm?.firstName,
        storeDetails: Itm?.storeDetails?.shopName,
        mobileNumber: Itm?.mobileNumber,
        email: Itm?.email,
        sellerEmail: Itm?.storeDetails?.sellerEmail,
        deviceType: Itm?.deviceType,
        created: moment(Itm?.created).format('L'),
        type: Itm?.storeDetails?.type,
        isRegistered: Itm?.isRegistered ? 'YES' : 'NO',
        isVerify: (
          <Toggle
            defaultChecked={Itm?.storeDetails?.isStoreVerified === 1 ? true : false}
            value={Itm?.storeDetails?.isStoreVerified === 1 ? 'true' : 'false'}
            onChange={() => handleVariFyChange(Itm?.id)}
          />
        ),
        status: (
          // Itm.isRegistered ?
            <Toggle
              checked={Itm.isSeller}
              onChange={() => handleStatusChange(Itm?.id)}
            />
        ),
        id: (
          <div>
            <Link to={`/seller/edit/${Itm?.id}`} title="Edit" className="mdi mdi-grease-pencil"></Link>
            <Link to={`/seller/view/${Itm?.id}`} title="View" className="mdi mdi-eye"></Link>
          </div>
        ),
      };
    });

    // Update the state with the new rows
    setTableData((prevTableData) => ({
      ...prevTableData,
      rows: updatedRows,
    }));
  }, [sellerList, location.search]);

  useEffect(() => {
    if (type === 'all') {
      setSellerList(SellerList)
    } else {
      var fltr = SellerList?.filter(itm => itm?.storeDetails?.type === type)
      setSellerList(fltr)
    }
  }, [type, SellerList])


  const handleStatusChange = (id) => {
    var data = {
      id: id
    }
    Axios.post(Constant.getAPI() + '/admin/toggleUserToSeller', data, config).then(res => {
      if (res.data.status) {
        var data = {
          startRange: '0',
          count: "1000"
        }
        dispatch(getSeller(data));
        Swal.fire({
          title: "Updated",
          text: "Seller Status has been Updated",
          type: "success",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        // window.location.reload()
      }
    })
  }
  const [searchTerm, setSearchTerm] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      dispatch(setLoading(true));
      fetchPageData(currentPage, searchTerm);
    }, 1000);

    setTimeoutId(id);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchTerm]);
  const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="form-group col-md-2 mt-3">
                  <div className="col-md-13">
                    <select
                      className="form-control"
                      value={type}
                      onChange={handleChange}
                    >
                      {/* <option >-Select Type-</option> */}
                      <option value="all">All Business </option>
                      <option value="Company">Company Business </option>
                      <option value="Establishment">
                        Establishment Business{" "}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="card-body">
                  <div data-test="datatable-input" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <input type="text" className="form-control form-control-sm ml-0 my-1" placeholder="Search Seller" aria-label="Search"
                      value={searchTerm} style={{ width: "30%" }}
                      onChange={(e) => setSearchTerm(e.target.value)}>
                    </input>
                  </div>
                  <MDBDataTable
                    theadColor="#2632384"
                    paging={false}
                    entries={20}
                    searching={false}
                    bordered
                    hover
                    data={tableData}
                    responsive={true}
                  />
                  <div className="pagination-controls">
                    <button
                      className="pagination-btn first-btn"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      className="pagination-btn prev-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn next-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      className="pagination-btn last-btn"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .pagination-controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
        }
        select{
          width: 27%;
        }
        .pagination-btn {
          background-color: #263238;
          color: white;
          border: none;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 14px;
        }
        
        .pagination-btn:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        
        .pagination-btn:not(:disabled):hover {
          background-color: #263233;
        }
      `}</style>
    </AUX>
  );
}

export default Seller

