/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { getDriverList } from "../../../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import Axios from "axios";
import Constant from "../../../Constant";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import DetailCard from '../../../components/Cards/DetailCard'
const OrderInvoice = () => {
  var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
  };

  const params = useParams();
  const dispatch = useDispatch();
  const driveList = useSelector((state) => state.Zeed.driveList);
  const [orderDetails, setOrderDetails] = useState({});
  const [vendorlist, setVendorlist] = useState([]);
  const [driveListLopp, setDriveListLopp] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [driverList, setDriver] = useState([]);
  const role = localStorage.getItem("role");

  useEffect(() => {
    getRolList();
  }, []);

  const getRolList = () => {
    var data = {
      isActive: true,
      startRange: 0,
    };
    dispatch(getDriverList(data));
    getOrderDetails();
  };

  useEffect(() => {
    if (driveList?.length > 0) {
      var data = driveList?.map((opt) => {
        return {
          label: opt.firstName + "" + opt.lastName,
          value: opt.id,
        };
      });
      setDriver(data);
    }
  }, [driveList]);

  const changeStatus = (e) => {
    const { value, name } = e.target;
    var data = {
      orderIds: params?.ord_id,
      deliveryStatusId: value,
    };
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
      },
    };
    Axios.post(Constant.getAPI() + "/order/changeDeliveryStatus", data, config)
      .then((res) => {
        if (res.data.status) {
          getOrderDetails();
          Swal.fire({
            title: "Order master status has been Changed",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .then((err) => {
        console.log("err", err);
        if (err) {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const assignedDriver = (e) => {
    const { value, name } = e.target;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
      },
    };
    var data = {
      driverId: value,
      orderId: params?.ord_id,
      //    shopId:this.props.match.params.id,
      //    date:this.props.match.params.date,
    };
    Axios.post(Constant.getAPI() + "/delivery/assignDriverV2", data, config)
      .then((res) => {
        if (res.data.status) {
          Swal.fire({
            title: "Driver has been Assigned",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .then((err) => {
        //    console.log('err', err);
        if (err) {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .then((err) => {
        console.log("err", err);
        if (err) {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const vendorAssignedDriver = (e, itemId) => {
    const { value } = e.target;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
      },
    };
    var data = {
      driverId: value,
      orderId: itemId,
    };
    Axios.post(
      Constant.getAPI() + "/delivery/vendorAssignDriverV2",
      data,
      config
    )
      .then((res) => {
        if (res.data.status) {
          Swal.fire({
            title: "Driver has been Assigned",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        Swal.fire({
          title: "Error",
          text: "An error occurred while assigning driver.",
          icon: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

  const getOrderDetails = () => {
    let setUrl = "";
    let adminUrl = "/order/backend-orderDetails";
    let vendorUrl = "/order/backend-vendor-orderDetails";
    let data = {};
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (role == "vendor" || uuidRegex.test(params?.ord_id)) {
      setUrl = vendorUrl;
      data = {
        orderSellerId: params?.ord_id,
      };
    } else {
      setUrl = adminUrl;
      data = {
        orderMasterId: params?.ord_id,
      };
    }
    Axios.post(Constant.getAPI() + setUrl, data, config)
      .then((res) => {
        setOrderDetails(res.data.data ? res.data.data : {});
        let vendorIds = []
        let diverIds = []

        let vendorList = []
        let diverList = []
        if (res.data.data && res.data.data.items) {
          for (let item of res.data.data.items) {
            if (!vendorIds.includes(item.storeDetails.id)) {
              vendorIds.push(item.storeDetails.id)
              vendorList.push({ ...item.storeDetails, statusId: item.statusId, driverId: item.driverId, orderId : item.orderId })
            }
          }
        }

        setVendorlist(vendorList)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const groupedItems = orderDetails?.items?.reduce((acc, item) => {
    console.log("orderDetail", orderDetails)
    const sellerId = item.storeDetails?.id;
    const shopName = item.storeDetails?.shopName;
    if (!acc[shopName]) {
      acc[shopName] = {
        items: [],
        sellerId: sellerId,
      };
    }
    acc[shopName].items.push(item);
    return acc;
  }, {});

  const [shopStatus, setShopStatus] = useState(
    Object.keys(groupedItems || {}).reduce((acc, shopName) => {
      acc[shopName] = "Pending";
      return acc;
    }, {})
  );

  const handleStatusChange = (shopName, newStatusId, newStatusName) => {
    let orderId = groupedItems[shopName].items[0].orderId;
    let sellerId = groupedItems[shopName].sellerId;
    const DATA = {
      sellerId: sellerId,
      orderId: orderId,
      deliveryStatusId: newStatusId,
    };
    Axios.post(
      Constant.getAPI() + "/order/changeSellerDeliveryStatus",
      DATA,
      config
    )
      .then((res) => {
        if (res.data.status) {
          getOrderDetails();
          Swal.fire({
            title: "Order status has been Changed",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setShopStatus((prevStatus) => ({
      ...prevStatus,
      [shopName]: newStatusName,
    }));
  };

  useEffect(() => { }, [orderDetails]);

  useEffect(() => {
    var subTotal = 0;
    if (orderDetails?.items !== undefined) {
      orderDetails?.items?.forEach((value) => {
        subTotal = subTotal + value?.totalPrice;
      });
      setSubTotal(subTotal); // Set the state with the calculated subTotal
    }
  }, [orderDetails]);

  const generatePDF = () => {
    const doc = new jsPDF();
    const imgData = "/assets/images/newlogo.png";
    const pageWidth = doc.internal.pageSize.getWidth();
    // Set the margin
    const margin = 10;

    // Add the logo
    doc.addImage(imgData, "PNG", margin, 10, 50, 20);
    // Order title
    doc.setFontSize(18);
    doc.text("Order Invoice", pageWidth - margin, 22, null, null, "right"); // Align 'Order Invoice' to the right

    // Order details
    const orderDetailsY = 47;
    const labelWidth = 50; // Approximate width of the longest label text (in pixels)
    // Set font size
    doc.setFontSize(11);
    // Order Date
    doc.setFont("helvetica", "bold");
    doc.text(
      "Order Date:",
      pageWidth - margin - labelWidth,
      orderDetailsY,
      null,
      null,
      "left"
    ); // Position label
    doc.setFont("helvetica", "normal");
    doc.text(
      moment(orderDetails?.created).format("MMMM Do YYYY"),
      pageWidth - margin,
      orderDetailsY,
      null,
      null,
      "right"
    ); // Position value
    // Invoice Number
    doc.setFont("helvetica", "bold");
    doc.text(
      "Invoice Number:",
      pageWidth - margin - labelWidth,
      orderDetailsY + 6,
      null,
      null,
      "left"
    ); // Position label
    doc.setFont("helvetica", "normal");
    doc.text(
      orderDetails?.invoice_number,
      pageWidth - margin,
      orderDetailsY + 6,
      null,
      null,
      "right"
    ); // Position value

    // Customer details
    const customerDetailsY = 47;
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(`Customer Details`, margin, customerDetailsY);
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold"); // Set font style to bold for labels
    doc.text("Name:", margin, customerDetailsY + 6);
    doc.text("Email:", margin, customerDetailsY + 12);
    doc.text("Mobile No:", margin, customerDetailsY + 18);

    doc.setFont("helvetica", "normal"); // Set font style to normal for user details
    doc.text(orderDetails?.User?.userName, margin + 15, customerDetailsY + 6);
    doc.text(orderDetails?.User?.email, margin + 15, customerDetailsY + 12);
    doc.text(
      orderDetails?.User?.mobileNumber,
      margin + 25,
      customerDetailsY + 18
    ); // Adjusted position for Mobile No

    // Customer address
    const addressDetailsY = customerDetailsY + 26;
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(`Customer Address`, margin, addressDetailsY);
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold"); // Set font to bold for labels
    doc.text("Type:", margin, addressDetailsY + 6);
    // doc.text('Avenue:', margin, addressDetailsY + 12);
    doc.text("Block:", margin, addressDetailsY + 12);
    doc.text("Flat:", margin, addressDetailsY + 18);
    doc.text("Area:", margin, addressDetailsY + 24);

    doc.setFont("helvetica", "normal"); // Set font to normal for address details
    doc.text(
      orderDetails?.addressDetails?.label,
      margin + 15,
      addressDetailsY + 6
    );
    // doc.text(orderDetails?.addressDetails?.avenue, margin + 18, addressDetailsY + 12);
    doc.text(
      orderDetails?.addressDetails?.block,
      margin + 18,
      addressDetailsY + 12
    );
    doc.text(
      orderDetails?.addressDetails?.flat,
      margin + 15,
      addressDetailsY + 18
    );
    doc.text(
      orderDetails?.addressDetails?.name || "",
      margin + 10,
      addressDetailsY + 24
    );

    // Payment details
    const paymentDetailsY = customerDetailsY + 26;
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Payment Details",
      pageWidth - margin - 15,
      paymentDetailsY,
      null,
      null,
      "right"
    );

    doc.setFontSize(11);
    const labelWidth1 = 50; // Width for the labels
    const spacing = 5; // Space after the colon

    // Set font to bold for labels
    doc.setFont("helvetica", "bold");
    doc.text(
      "Payment Type:",
      pageWidth - margin - labelWidth1,
      paymentDetailsY + 10,
      null,
      null,
      "left"
    );
    doc.text(
      "Refund Status:",
      pageWidth - margin - labelWidth1,
      paymentDetailsY + 16,
      null,
      null,
      "left"
    );
    doc.text(
      "Payment Status:",
      pageWidth - margin - labelWidth1,
      paymentDetailsY + 22,
      null,
      null,
      "left"
    );
    doc.text(
      "Order Status:",
      pageWidth - margin - labelWidth1,
      paymentDetailsY + 28,
      null,
      null,
      "left"
    );

    // Set font to normal for values
    doc.setFont("helvetica", "normal");
    doc.text(
      orderDetails?.paymentType,
      pageWidth - margin - labelWidth1 + labelWidth1,
      paymentDetailsY + 10,
      null,
      null,
      "right"
    );
    doc.text(
      orderDetails?.refundStatus,
      pageWidth - margin - labelWidth1 + labelWidth1,
      paymentDetailsY + 16,
      null,
      null,
      "right"
    );
    doc.text(
      orderDetails?.paymentStatus,
      pageWidth - margin - labelWidth1 + labelWidth1,
      paymentDetailsY + 22,
      null,
      null,
      "right"
    );
    doc.text(
      orderDetails?.orderStatus,
      pageWidth - margin - labelWidth1 + labelWidth1,
      paymentDetailsY + 28,
      null,
      null,
      "right"
    );

    // Order summary
    const orderSummaryY = addressDetailsY + 40;
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(`Order summary`, margin, orderSummaryY);

    const items = orderDetails?.items?.map((item, index) => {
      // Convert the image URL to an image object
      const imageUrl = item.Media[0].url;
      const img = new Image();
      img.src = imageUrl;

      // Return an array with the modified URL and other item details
      return [
        index + 1,
        img, // Use the image object directly
        item?.title,
        item?.shopName,
        `${item?.totalPrice} KWD`,
      ];
    });

    doc.autoTable({
      startY: orderSummaryY + 10,
      body: items,
      margin: { top: 0, bottom: 10, left: 10, right: 10 }, // Remove all margins
      head: [["#", "Product", "Product Name", "Shop Name", "Price"]],
      didDrawCell: (data) => {
        if (data.column.index === 1 && data.cell.section === "body") {
          // Check if it's the 'Product' column
          const imageUrl = data.cell.raw; // The URL of the image
          doc.addImage(
            imageUrl,
            "JPEG",
            data.cell.x + 2,
            data.cell.y + 2,
            10,
            10
          ); // Adjust the position and size
        }
      },
      bodyStyles: { minCellHeight: 15, cellPadding: 2 },
      styles: { overflow: "linebreak" }, // Adjust if text overflows
    });
    const subTotal = orderDetails?.items?.reduce(
      (acc, item) => acc + parseInt(item?.price),
      0
    );
    const deliveryCharge = orderDetails?.deliveryCharge;
    const discountAmount = orderDetails?.discountAmount;
    const grandTotal = subTotal + deliveryCharge;

    const summaryY = doc.autoTable.previous.finalY + 10;

    // Define data for the table
    const tableData = [
      ["SUB TOTAL", `${subTotal} KWD`],
      ["DELIVERY CHARGE", `${deliveryCharge} KWD`],
      ["DISCOUNT AMOUNT", `${discountAmount} KWD`],
      ["GRAND TOTAL", `${grandTotal} KWD`],
    ];

    // Define custom column widths
    const columnWidths = [50, 40]; // Adjust the widths as needed

    // Calculate the starting position for the table to align it to the right side of the page
    const tableX =
      pageWidth - margin - columnWidths.reduce((acc, width) => acc + width, 0); // Subtracting total column widths from page width

    // Create the table
    doc.autoTable({
      startY: summaryY,
      startX: tableX, // Align the table to the right side of the page
      // head: [['Description', 'Amount']],
      body: tableData,
      theme: "grid",
      styles: { fontStyle: "normal", fontSize: 11 },
      columnStyles: {
        0: { fontStyle: "bold", columnWidth: columnWidths[0] }, // Setting the first column (Description) to bold and applying custom width
        1: { halign: "right", columnWidth: columnWidths[1] }, // Aligning the second column (Amount) to the right and applying custom width
      },
      margin: { left: 110 },
    });

    doc.save(`invoice_${orderDetails?.invoice_number}.pdf`);
  };

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };

  return (
    <div className="page-content-wrapper">
      <div className="container-fluid">
        <div className="card m-b-20" >
          <div className="card-header" style={divStyle}>
          <h4 className="pull-right font-16">
              <strong>Order #{orderDetails?.invoiceNumber}</strong>
            </h4>
            <div>
            <a
              onClick={generatePDF}
              className="btn btn-success waves-effect waves-light pull-right ml-3"
            >
              <i className="fa fa-print"></i>
            </a>
            
            {role !== "vendor" && (
              <div className="pull-right ml-3">
                <strong>Order Status</strong>
                <select
                  className="orderInvoiceSelect mt-0"
                  onChange={changeStatus}
                  value={orderDetails?.orderStatusId}
                >
                  <option value="">- Select Status -</option>
                  {orderDetails?.orderStatusList?.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.id === "b6b76fe2-1081-4ae4-ae0d-55f11aa716af"
                        ? "At store"
                        : data.displayStatus}
                    </option>
                  ))}
                </select>
              </div>
            )}
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 mb-3">
                <DetailCard
                  title="Order Detail"
                  rows={[
                    { label: 'Order Status', value: orderDetails?.orderStatus },
                    { label: 'Place Time', value: moment(orderDetails?.created).format(Constant.getDateFormat()) },
                    { label: 'Customer Name', value: orderDetails?.User?.userName },
                    { label: 'Payment Type', value: orderDetails?.paymentType },
                    { label: 'Item Qty', value: orderDetails?.totalItems },
                    { label: 'Delivery between', value: orderDetails?.deliveryTimeSlot }
                  ]}
                />

              </div>
              <div className="col-6 mb-3">
                <DetailCard
                  title="Customer Detail"
                  rows={[
                    { label: 'User Name', value: orderDetails?.User?.userName },
                    { label: 'Email', value: orderDetails?.User?.email },
                    { label: 'Contact', value: orderDetails?.User?.mobileNumber },
                    // { label: 'Status', value: orderDetails?.User?.status }
                  ]}
                />

              </div>
              <div className="col-6 mb-3">
                <DetailCard
                  title="Delivery Address"
                  rows={[
                    { label: 'Name', value: (orderDetails?.addressDetails?.name ?? '') },
                    { label: 'Address', value: orderDetails?.addressDetails ? Constant.formatAddress(orderDetails?.addressDetails) : '' },
                    { label: 'City', value: orderDetails?.addressDetails?.City?.name },
                    { label: 'State', value: orderDetails?.addressDetails?.City?.State?.name },
                    { label: 'Country', value: orderDetails?.addressDetails?.City?.State?.Country?.name },
                    { label: 'Contact', value: (orderDetails?.addressDetails?.mobile ?? '') + (orderDetails?.addressDetails?.alternate_mobile ? ("/" + orderDetails?.addressDetails?.alternate_mobile) : '  ') },
                  ]}
                />
              </div>
              {orderDetails?.vendorDetails && (
                <div className="col-6 mb-3">
                  <DetailCard
                    title="Vendor details"
                    rows={[
                      { label: 'User Name', value: orderDetails?.vendorDetails?.userName },
                      { label: 'Email', value: orderDetails?.vendorDetails?.email },
                      { label: 'Contact', value: orderDetails?.vendorDetails?.mobileNumber },
                    ]}
                  />
                </div>
              )}

              {
                role !== "vendor" ? <div className="col-12  mb-3">
                  <div style={{
                    backgroundColor: 'white',
                    border: '1px solid rgb(167, 167, 167)',
                    borderRadius: '8px',
                    overflow: 'auto'
                  }}>
                    <div className="card-header text-center">
                      Vendor List
                    </div>
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Vendor</th>
                          <th scope="col">Status</th>
                          <th scope="col">Comment</th>
                          <th scope="col">Assign Driver</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendorlist.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{item.shopName}</td>
                              <td>
                                <select
                                  onChange={(e) =>
                                    handleStatusChange(
                                      item.shopName,
                                      e.target.value,
                                      e.target[
                                        e.target.selectedIndex
                                      ].text
                                    )
                                  }
                                  value={item.statusId}
                                >
                                  {orderDetails.vendorStatusList.map(
                                    (status, id) => (
                                      <option
                                        key={id}
                                        value={status.id}
                                      >
                                        {status.id ===
                                          "b6b76fe2-1081-4ae4-ae0d-55f11aa716af"
                                          ? "At store"
                                          : status.displayStatus}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>
                              <td></td>
                              <td>   {item.statusId ===
                                "54f95aa1-e90d-4eac-a0e0-eeb8640f535b" && (
                                  <div>
                                    <select
                                      onChange={(e) =>
                                        vendorAssignedDriver(
                                          e,
                                          item.orderId
                                        )
                                      }
                                      value={item.driverId}
                                      disabled={role == "vendor"}
                                    >
                                      <option value="">- Select Status -</option>
                                      {driverList &&
                                        driverList.map(
                                          (driver) => (
                                            <option
                                              key={driver.value}
                                              value={driver.value}
                                            >
                                              {driver.label}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </div>
                                )}</td>

                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div> : ''
              }

              <div className="col-12  mb-3">
                <div style={{
                  backgroundColor: 'white',
                  border: '1px solid rgb(167, 167, 167)',
                  borderRadius: '8px',
                  overflow: 'auto'
                }}>
                  <div className="card-header text-center">
                    Product List
                  </div>
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Image</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Product</th>
                        {role !== "vendor" ? <th scope="col">Vendor</th> : ''}
                        <th scope="col">Price</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Total</th>
                        {/* <th scope="col">Status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        orderDetails?.items && orderDetails?.items.length
                        && orderDetails?.items.map((item, i) => {
                          return (
                            <tr>
                              <th scope="row">{i + 1}</th>
                              <td ><img style={{ height: "25px" }} src={item?.Media && item?.Media[0]?.url ? item?.Media[0]?.url : ""} /></td>
                              <td>{item.sku}</td>
                              <td>{item.title}</td>
                              {role !== "vendor" ? <td>{item.storeDetails.shopName}</td> : ''}
                              <td>{item.price.toFixed(3)} KWD</td>
                              <td>{item.selectedQuantity}</td>
                              <td>{item.totalPrice.toFixed(3)} KWD</td>
                              {/* <td></td> */}

                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              {role !== "vendor" ? (
                <div className="col-6">
                  <div style={{
                    backgroundColor: 'white',
                    border: '1px solid rgb(167, 167, 167)',
                    borderRadius: '8px',
                    overflow: 'auto'
                  }}>
                    <div className="card-header text-center">
                      Order Status
                    </div>

                    <div className="order-status-container d-flex printHideDiv">
                      <div className="pr-3">
                        <strong>Order Status</strong>
                        <select
                          className="orderInvoiceSelect"
                          onChange={changeStatus}
                          value={orderDetails.orderStatusId}
                        >
                          <option value="">- Select Status -</option>
                          {orderDetails.orderStatusList &&
                            orderDetails.orderStatusList.map((data) => (
                              <option key={data.id} value={data.id}>
                                {data.id ===
                                  "b6b76fe2-1081-4ae4-ae0d-55f11aa716af"
                                  ? "At store"
                                  : data.displayStatus}
                              </option>
                            ))}
                        </select>
                      </div>

                      {(orderDetails.orderStatusId === "4cc48c4c-baed-426d-9a6d-a913b7f7719f" ||
                        orderDetails.orderStatusId === "e9b6b5c2-9dda-4c34-8fce-0666a173ddb8") ? (
                        <div>
                          <strong>Assign Driver</strong>
                          <select
                            className="orderInvoiceSelect"
                            id="driver-select"
                            onChange={assignedDriver}
                            value={orderDetails.masterDriverId}
                          >
                            <option value="">- Select Driver -</option>
                            {driverList &&
                              driverList.map((driver) => (
                                <option key={driver.value} value={driver.value}>
                                  {driver.label}
                                </option>
                              ))}
                          </select>
                        </div>
                      ) : null}
                    </div>

                  </div>
                </div>
              ) : null}
              {role == "vendor" ? (
                <div className="col-6">
                  <div style={{
                    backgroundColor: 'white',
                    border: '1px solid rgb(167, 167, 167)',
                    borderRadius: '8px',
                    overflow: 'auto'
                  }}>
                    <div className="card-header text-center">
                      Order Status
                    </div>
                    <div className="mt-3 ml-3">
                      <strong>Order Status</strong>
                    </div>
                    {vendorlist[0] ?
                      <div className="order-status-container d-flex printHideDiv">
                        <div className="pr-1">
                          <select
                            onChange={(e) =>
                              handleStatusChange(
                                vendorlist[0].shopName,
                                e.target.value,
                                e.target[
                                  e.target.selectedIndex
                                ].text
                              )
                            }
                            className="orderInvoiceSelect"
                            value={vendorlist[0].statusId}
                          >
                            {orderDetails.vendorStatusList.map(
                              (status, id) => (
                                <option
                                  key={id}
                                  value={status.id}
                                >
                                  {status.id ===
                                    "b6b76fe2-1081-4ae4-ae0d-55f11aa716af"
                                    ? "At store"
                                    : status.displayStatus}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {vendorlist[0].statusId ===
                          "54f95aa1-e90d-4eac-a0e0-eeb8640f535b" && (
                            <div>
                              <select
                                onChange={(e) =>
                                  vendorAssignedDriver(
                                    e,
                                    vendorlist[0].orderId
                                  )
                                }
                                className="orderInvoiceSelect"
                                value={vendorlist[0].driverId}
                                disabled={role == "vendor"}
                              >
                                {driverList &&
                                  driverList.map(
                                    (driver) => (
                                      <option
                                        key={driver.value}
                                        value={driver.value}
                                      >
                                        {driver.label}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          )}
                      </div> : ''}

                  </div>
                </div>
              ) : null}
              <div className="col-6 ">
                <div style={{
                  backgroundColor: 'white',
                  border: '1px solid rgb(167, 167, 167)',
                  borderRadius: '8px',
                  overflow: 'auto'
                }}>
                  <div className="card-header text-center">
                    Payment Details
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex p-1">
                      <div className="col-4"> Order Amount</div>
                      <div className="col-8">{subTotal ? subTotal.toFixed(3) : ''} KWD</div>
                    </li>
                  </ul>
                  {role !== 'vendor' && (
                  <>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex p-1">
                        <div className="col-4">Delivery Fee </div>
                        <div className="col-8">{orderDetails?.deliveryCharge ? orderDetails?.deliveryCharge.toFixed(3) : ''} KWD</div>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex p-1">
                        <div className="col-4">Discount Amount </div>
                        <div className="col-8">{orderDetails?.discountAmount ? orderDetails?.discountAmount.toFixed(3) : '0'} KWD</div>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex p-1">
                        <div className="col-4">Total </div>
                        <div className="col-8">{orderDetails?.grandTotal ? orderDetails?.grandTotal.toFixed(3) : ''} KWD</div>
                       
                      </li>
                    </ul>
                    </>
                  )}
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex p-1">
                      <div className="col-4">Payment Method </div>
                      <div className="col-8">{orderDetails?.paymentType ? orderDetails?.paymentType : ''}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default OrderInvoice;
