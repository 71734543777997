module.exports = {
  getAPI: function () {
    let hostName = window.location.hostname;
    let devString = "-dev.zeedco.co";
    let localStr = 'localhost'
    let url = "https://api.zeedco.co"
    if (hostName.includes(devString)) {
      url = 'https://stg-api.zeedco.co';
    }
    else if (hostName.includes(localStr)) {
      url = 'http://localhost:5000';
    }
    return url;
    // return "http://dev.infoware.xyz:8042"
    // return "https://stg-api.zeedco.co"
    // return "http://localhost:8042"
  },
  getDefaultCurrrency: function () {
    return "KWD";
  },
  getLanguageList: function () {
    var languages = [
      {
        id: "en",
        name: "English",
      },
      {
        id: "ar",
        name: "Arabic",
      },
    ];
    return languages;
  },
  getDateFormat: function () {
    return "MMMM Do YYYY";
  },
  formatAddress: (address) => {
    if (!address) {
      return ''
    }
    return [
      address.name.trim(),
      address.block && `Block ${address.block.trim()}`,
      address.street && `Street ${address.street.trim()}`,
      address.avenue && `Avenue ${address.avenue.trim()}`,
      address.flat && `Flat ${address.flat.trim()}`,
      address.floor && `Floor ${address.floor.trim()}`,
      address.apartment && `Apartment ${address.apartment.trim()}`,
      address.landmark && `Landmark: ${address.landmark.trim()}`,
      address.direction && `Direction: ${address.direction.trim()}`,
      address.mobile && `Mobile: ${address.mobile.trim()}`,
      address.label && `Label: ${address.label.trim()}`
    ].filter(Boolean).join(', ');
  }
};