import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { MDBDataTable } from "mdbreact";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GET_ORDER } from "../../../redux/type";
import axios from "axios";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";
var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const MasterCancelledOrders = () => {
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const ordrList = useSelector((state) => state.Zeed.orders);
  const location = useLocation();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [orderStatusParam, setActiveParam] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderStatus = queryParams.get("orderStatusId") || "";
    dispatch(setLoading(true));
    setActiveParam(orderStatus);
    fetchPageData(1, orderStatus);
  }, [location.search]);

  const fetchPageData = (pageNum, orderStatusParam) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
      orderStatusId: orderStatusParam
        ? orderStatusParam
        : "cee1b25e-2b10-11ef-bd7f-0ad94c5b94d5",
      type: 'O',
    };
    // let setUrl = "";
    let adminUrl = "/order/backend-getAll";
    // let vendorUrl = "/order/backend-vendor-getAll";
    // if (role == "vendor") {
    //   setUrl = vendorUrl;
    // } else {
    //   setUrl = adminUrl;
    // }
    axios
      .post(Constant.getAPI() + adminUrl, DATA, config)
      .then((res) => {
        // window.setLoading(true)
        dispatch({
          type: GET_ORDER,
          payload: res.data.data,
          totalRows: res.data.totalRows,
        });
        setTotalRows(res.data.totalRows);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    dispatch(setLoading(true));
    fetchPageData(pageNum, orderStatusParam);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    fetchPageData(1);
  };


  const filteredUsList = ordrList.filter((user) => {
    if (!orderStatusParam) {
      return true;
    }
    if (
      orderStatusParam == "383a1c6f-d1ca-4aa8-ae70-4ba71f015e2b" &&
      user.orderStatus === "Placed"
    ) {
      return true;
    }
    if (
      orderStatusParam == "c5ce32ce-d8e5-4c4c-b679-e892440595e9" &&
      user.orderStatus === "Pending"
    ) {
      return true;
    }
    if (
      orderStatusParam == "18add9a7-c9b2-47d2-8239-85020ca1db88" &&
      user.orderStatus === "Delivered"
    ) {
      return true;
    }
    if (
      orderStatusParam == "4cc48c4c-baed-426d-9a6d-a913b7f7719f" &&
      user.orderStatus === "Being Wrapped Up"
    ) {
      return true;
    }
    if (
      orderStatusParam == "cee1b25e-2b10-11ef-bd7f-0ad94c5b94d5" &&
      user.orderStatus === "Cancelled"
    ) {
      return true;
    }
    // if (
    //   orderStatusParam == "54f95aa1-e90d-4eac-a0e0-eeb8640f535b" &&
    //   user.orderStatus === "Ready for pickup"
    // ) {
    //   return true;
    // }
    if (
      orderStatusParam == "54b7c7d8-5715-4536-a330-d2641b0b8bf7" &&
      user.orderStatus === "Not delivered"
    ) {
      return true;
    }
    // if (
    //   orderStatusParam == "b6b76fe2-1081-4ae4-ae0d-55f11aa716af" &&
    //   user.orderStatus === "Reached at warehouse"
    // ) {
    //   return true;
    // }
    return false;
  });

  const columns = [
    {
      label: "Order ID",
      field: "invoiceNumber",
    },
    // {
    //   label: "Area",
    //   field: "Area",
    // },
    {
      label: "Date-Time",
      field: "createdAt",
    },
    {
      label: "Customer Name",
      field: "userName",
    },
    {
      label: "Customer Email",
      field: "email",
    },
    {
      label: "Customer Phone",
      field: "phone",
    },
    // {
    //   label: "Time Slot",
    //   field: "deliveryTimeSlot",
    // },
    {
      label: "Order Total",
      field: "orderTotal",
    },
    {
      label: "Payment Status",
      field: "paymentStatus",
    },
    {
      label: "Order Status",
      field: "orderStatus",
    },
    {
      label: "Action",
      field: "id",
    },
  ];

  const rows = filteredUsList.map((Item) => ({
    invoiceNumber: Item.invoiceNumber,
    // User: Item.customer.userName,
    Area: Item?.addressDetails?.City?.name || "",
    createdAt: moment.unix(Item.createdAt).format("DD-MMM-YYYY | hh:mm A"),
    deliveryTimeSlot: Item.deliveryTimeSlot,
    orderTotal: Item.orderTotal,
    userName:
      role == "vendor" ? Item?.user?.userName : Item?.customer?.userName,
    email: role == "vendor" ? Item?.user?.email : Item?.customer?.email,
    phone:
      role == "vendor"
        ? Item?.user?.mobileNumber
        : Item?.customer?.mobileNumber,
    orderStatus:
      Item.orderStatus === "Placed" ? (
        <b
          style={{
            color: "green",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ) : Item.orderStatus === "Reached at warehouse" ? (
        <b
          style={{
            color: "blue",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          At store
        </b>
      ) : (
        <b
          style={{
            color: "red",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ),
    // orderStatus: Item.orderStatus === "Placed" ? <b style={{ color: 'green', marginTop: "0", marginBottom: 0, fontWeight: "600" }}>{Item.orderStatus}</b> : <b style={{ color: 'red', marginTop: "0", marginBottom: 0, fontWeight: "600"}}>{Item.orderStatus}</b>,
    paymentStatus:
      Item.paymentStatus === "Pending" ? (
        <p
          style={{
            color: "red",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          Pending
        </p>
      ) : Item.paymentStatus === "received" ? (
        <p style={{ color: "green", marginTop: "0", marginBottom: 0 }}>
          Success
        </p>
      ) : (
        <p style={{ color: "red", marginTop: "0", marginBottom: 0 }}>Failed</p>
      ),
    paymentStatus: (() => {
      switch (Item.paymentStatus) {
        case "Pending":
          return (
            <p
              style={{
                color: "orange",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Pending
            </p>
          );
        case "Received":
          return (
            <p
              style={{
                color: "green",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Success
            </p>
          );
        case "notProcessed":
          return (
            <p
              style={{
                color: "red",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Failed
            </p>
          );
        default:
          return (
            <p
              style={{
                color: "red",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Failed
            </p>
          );
      }
    })(),
    id: <Link to={`/orders/${Item.id}`} title="View" className="mdi mdi-eye"></Link>,
  }));

  const data = {
    columns,
    rows,
  };
  const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <MDBDataTable
                    bordered
                    theadColor="#2632384"
                    paging={false}
                    hover
                    entries={20}
                    data={data}
                  />
                  <div className="pagination-controls">
                    {/* <select class="" onChange={handlePerPageChange} value={perPage}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={40}>40</option>
                      <option value={80}>80</option>
                      <option value={100}>100</option>
                    </select> */}
                    <button
                      className="pagination-btn first-btn"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      className="pagination-btn prev-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn next-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      className="pagination-btn last-btn"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .pagination-controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
        }
        select{
          width: 27%;
        }
        .pagination-btn {
          background-color: #263238;
          color: white;
          border: none;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 14px;
        }
        
        .pagination-btn:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        
        .pagination-btn:not(:disabled):hover {
          background-color: #263233;
        }
      `}</style>
    </AUX>
  );
};

export default MasterCancelledOrders;
